import { graphql, useStaticQuery } from "gatsby"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import { StaticImage } from "gatsby-plugin-image"
import * as React from "react"
import Emphasis from "../components/emphasis"
import H2 from "../components/h2"
import H3 from "../components/h3"
import Layout from "../components/layout"
import Seo from "../components/seo"

export default function ServicesPage() {
  const data = useStaticQuery(graphql`
    query ServicePageQuery {
      allContentfulStats(limit: 1, sort: { fields: updatedAt, order: DESC }) {
        nodes {
          squareFeetContainmentLinerInstalled
        }
      }
    }
  `)
  console.debug("Service Page Data: ", data)

  return (
    <Layout>
      <Seo title="Services" />
      <div className="py-8 px-8 lg:px-16">
        <H2>Services</H2>
        <section className="py-8 flex flex-col lg:flex-row w-full lg:space-x-8 justify-center">
          {/* TODO: Consider rebuilding these to make it more evident they're clickable buttons */}
          <div className="basis-1/3 flex items-stretch">
            <AnchorLink
              to="/services#spray-foam-insulation"
              className="block h-full hover:text-primary-highlighted"
            >
              <div className="text-center font-bold">Spray Foam Insulation</div>
              <figure className="py-4 h-full text-center">
                <StaticImage
                  src="../images/stock/spray-foam-gun.jpeg"
                  alt=""
                  objectFit="cover"
                  objectPosition="center"
                  placeholder="blurred"
                  className="h-full rounded-xl"
                />
              </figure>
            </AnchorLink>
          </div>
          <div className="basis-1/3 flex items-stretch">
            <AnchorLink
              to="/services#polyurea-coating"
              className="block h-full hover:text-primary-highlighted"
            >
              <div className="text-center font-bold">Polyurea Coating</div>
              <figure className="py-4 h-full text-center">
                <StaticImage
                  src="../images/polyurea-coating-job.jpg"
                  alt=""
                  objectFit="cover"
                  objectPosition="center"
                  placeholder="blurred"
                  className="h-full rounded-xl"
                />
              </figure>
            </AnchorLink>
          </div>
          <div className="basis-1/3 flex items-stretch">
            <AnchorLink
              to="/services#blown-in-insulation"
              className="block h-full hover:text-primary-highlighted"
            >
              <div className="text-center font-bold">Blown In Insulation</div>
              <figure className="py-4 h-full text-center">
                <StaticImage
                  src="../images/stock/blown-in-insulation-down-wall.jpeg"
                  alt=""
                  objectFit="cover"
                  objectPosition="center"
                  placeholder="blurred"
                  className="h-full rounded-xl"
                />
              </figure>
            </AnchorLink>
          </div>
        </section>
        <section id="spray-foam-insulation" className="py-8">
          <H3>Spray Foam Insulation</H3>
          <p className="py-4">
            Spray foam insulation is simply the highest quality insulation
            solution available. It creates an airtight seal around the building
            envelope by filling and expanding in cracks and crevices to
            eliminate air leaks and drafts. This results in an environmentally
            friendly, energy optimized structure that can dramatically improve
            HVAC efficiency in your home or building and reduce overall energy
            costs, along with a myriad of other benefits.
          </p>
          <figure className="py-8 text-center">
            <StaticImage
              src="../images/job-well-done.jpeg"
              alt="Spray foam insulation job"
              objectFit="cover"
              objectPosition="center"
              placeholder="blurred"
              className="w-full lg:!w-1/2 aspect-video rounded-xl"
            />
          </figure>
          <div className="flex flex-col lg:flex-row">
            <div className="w-full lg:!w-1/3">
              <Emphasis>Use Cases</Emphasis>
              <ul className="grow list-disc list-outside px-4 py-6">
                <li>New construction</li>
                <li>Attics</li>
                <li>Crawl spaces</li>
                <li>Commerical buildings</li>
                <li>Pole barns</li>
              </ul>
            </div>
            <div className="w-full lg:!w-1/3">
              <Emphasis>Benefits</Emphasis>
              <ul className="grow list-disc list-outside px-4 py-6">
                <li>Prevents heat transfer</li>
                <li>Expands to fill gaps</li>
                <li>Acts as airtight seal for building envelope</li>
                <li>Noise reduction</li>
                <li>Inhibits mold, mildew and bacterial growth</li>
              </ul>
            </div>
          </div>
        </section>
        <section id="polyurea-coating" className="py-8">
          <H3>Polyurea Coating</H3>
          <p className="py-4">
            Polyurea coatings create a strong, flexible coating that adheres to
            a variety of indoor or outdoor surfaces at any angle to create a
            single, seamless waterproof, protective layer.
          </p>
          <figure className="py-8 text-center">
            <StaticImage
              src="../images/polyurea-coating-job.jpg"
              alt="Polyurea coating job"
              objectFit="cover"
              objectPosition="center"
              placeholder="blurred"
              className="w-full lg:!w-1/2 aspect-video rounded-xl"
            />
          </figure>
          <p className="pb-4 text-center">
            <Emphasis>
              Over{" "}
              {data.allContentfulStats.nodes[0].squareFeetContainmentLinerInstalled.toLocaleString()}{" "}
              Sq. Ft. Installed!
            </Emphasis>
          </p>
          <div className="flex flex-col lg:flex-row">
            <div className="w-full lg:!w-1/3">
              <Emphasis>Use Cases</Emphasis>
              <ul className="grow list-disc list-outside px-4 py-6">
                <li>Roof/Deck waterproofing</li>
                <li>Truck bedliners</li>
                <li>Primary and secondary containment for tank linings</li>
                <li>Pipeline corrosion control</li>
                <li>Much, much more...</li>
              </ul>
            </div>
            <div className="w-full lg:!w-1/3">
              <Emphasis>Benefits</Emphasis>
              <ul className="grow list-disc list-outside px-4 py-6">
                <li>Easy application to most surfaces</li>
                <li>Reduced maintenance costs</li>
                <li>
                  Rugged protection from water, chemicals, UV light, impact,
                  rust and corrosion
                </li>
                <li>
                  Flexible and able to expand so that can move in shifting
                  environments without breaking
                </li>
                <li>Dries quickly</li>
              </ul>
            </div>
          </div>
        </section>
        <section id="blown-in-insulation" className="py-8">
          <H3>Blown In Insulation</H3>
          <p className="py-4">
            Blown In Insulation can be a more affordable alternative to Spray
            Foam Insulation with some of the same benefits. Blown In Insulation
            is easy to install and can be blown into existing wall and floor
            spaces to create an airtight, protective layer of insulation
            material.
          </p>
          <figure className="py-8 text-center">
            <StaticImage
              src="../images/stock/blown-in-attic-insulation.jpeg"
              alt="Blown in insulation job"
              objectFit="cover"
              objectPosition="center"
              placeholder="blurred"
              className="w-full lg:!w-1/2 aspect-video rounded-xl"
            />
          </figure>
          <div className="flex flex-col lg:flex-row">
            <div className="w-full lg:!w-1/3">
              <Emphasis>Use Cases</Emphasis>
              <ul className="grow list-disc list-outside px-4 py-6">
                <li>Existing structures with no insulation in walls</li>
                <li>Attic floors</li>
                <li>Joist / Stud Cavities</li>
              </ul>
            </div>
            <div className="w-full lg:!w-1/3">
              <Emphasis>Benefits</Emphasis>
              <ul className="grow list-disc list-outside px-4 py-6">
                <li>Quick and easy Installation</li>
                <li>Affordable</li>
                <li>Good insulation properties</li>
                <li>More airtight than rolled batting</li>
                <li>Fire resistance</li>
              </ul>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  )
}
